<template>
  <div class="detail-page">
    <Header />
    <Balance  @refresh="getBalance" :data="balanceData" />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Balance from './balance';
import {fetchBalance} from "@/api/home";

export default {
  components: {
    Header,
    Balance,
  },
  data() {
    return {
      balanceData: {},
      
    }
  },
  activated() {
    this.getBalance({});
  },
  methods: {
    async getBalance({ date } = {}) {
      const res = await fetchBalance({ endDate: date });
      this.balanceData = res.data;
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;

}
</style>

