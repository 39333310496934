<!-- 余额明细 -->
<template>
  <div class="balance">
    <div class="content">
      <div class="header" @click="openDatePciker">
        <div class="label">选择截止日期</div>
        <div>{{year}}</div>
        <div class="date-label">年</div>
        <div>{{month}}</div>
        <div class="date-label">月</div>
        <div>{{day}}</div>
        <div class="date-label">日</div>
        <img class="date-icon" src="../../../assets/images/home/date.png" />
      </div>
      <div class="body">
        <div class="item">
          <div class="label">可用余额/元</div>
          <div class="num">{{handlePrice(data.kyyue)}}</div>
        </div>
        <div class="item">
          <div class="label">可用返利/元</div>
          <div class="num">{{data.backPrice || 0}}</div>
        </div>
        <div class="item">
          <div class="label">订单占用金额/元</div>
          <div class="num">{{data.zyyue || 0}}</div>
        </div>
        <div class="item">
          <div class="label">未结算费用/元</div>
          <div class="num">{{data.tranClosePrice || 0}}</div>
        </div>
        <div class="item">
          <div class="label">保证金/元</div>
          <div class="num">{{data.zbzj || 0}}</div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <router-link to="/info/accountBalance">
        <Button class="button" size="mini-plain">查看全部</Button>
      </router-link>
    </div>
    <div class="date-container" @click="closeDatePicker" v-if="isShowDatePicker">
      <DatetimePicker type="date" @confirm="onDateConfirm" :value="currentDate"/>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/common/button/CustomButton';
import { DatetimePicker } from 'vant';
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    Button,
    DatetimePicker,
  },
  computed: {
    year() {
      return this.currentDate.getFullYear();
    },
    month() {
      return `00${this.currentDate.getMonth() + 1}`.slice(-2);
    },
    day() {
      return `00${this.currentDate.getDate()}`.slice(-2);
    }
  },
  data() {
    return {
      currentDate: new Date(),
      isShowDatePicker: false
    }
  },
  methods: {
    handlePrice(price) {
      return price;
      // if (parseFloat(price) > 100000) {
      //   return `${parseInt(parseFloat(price) / 10000).toFixed(2)}w`;
      // } else {
      //   return price || 0;
      // }
    },
    closeDatePicker() {
      this.isShowDatePicker = false;
    },
    openDatePciker() {
      this.isShowDatePicker = true;
    },
    onDateConfirm(date) {
      this.currentDate = date;
    },
    refresh() {
      this.$emit('refresh', {
        date: `${this.year}-${this.month}-${this.day}`,
      });
    }
  },
  watch: {
    currentDate() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
}
</script>

<style lang="scss" scoped>
.balance {
  .content {
    width: 326px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin: 10px auto 0;
    .header {
      height: 44px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EAEAEA;
      box-sizing: border-box;
      padding: 0 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2C2C2C;
      .label {
        line-height: 17px;
        color: #CECECE;
        flex: 1;
        text-align: left;
      }
      .date-label {
        margin: 0 4px;
        color: #CECECE;
      }
      .date-icon {
        width: 14px;
        height: 12px;
        margin-bottom: 3px;
      }
    }
  }
  .body {
    padding: 11px 12px 23px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      margin-top: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      word-break: keep-all;
      white-space: nowrap;
      .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #818181;
      }
      .num {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        color: #2C2C2C;
      }
    }
  }
  .button {
    width: 260px;
    &-container {
      width: 100%;
      display: flex;
      margin-top: 8px;
      padding-bottom: 4px;
      justify-content: center;
    }
  }
  .date-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>