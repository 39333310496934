<template>
  <div class="detail-page">
    <Header />
    <DateRangePicker @changeStart="handleChangeStart" @changeEnd="handleChangeEnd" />

    <div class="balance">
      <div class="content">
        <div class="header" v-if="false" @click="openDatePciker">
          <div class="label">选择截止日期</div>
          <div>{{year}}</div>
          <div class="date-label">年</div>
          <div>{{month}}</div>
          <div class="date-label">月</div>
          <div>{{day}}</div>
          <div class="date-label">日</div>
          <img class="date-icon" src="../../../assets/images/home/date.png" />
        </div>
        <div class="body">
          <div class="item">
            <div class="label">期初返利余额/元:</div>
            <div class="num">{{data.initialBackPrice || 0}}
            </div>
          </div>
          <div class="item">
            <div class="label">新增返利余额/元:</div>
            <div class="num" @click="onAddBackDetail">{{data.addBackPrice || 0}}
              <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
            </div>
          </div>
          <div class="item">
            <div class="label">耗用返利余额/元:</div>
            <div class="num" @click="onReduceBackDetail">{{data.reduceBackPrice || 0}}
              <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
            </div>
          </div>
          <div class="item">
            <div class="label">可用返利余额/元:</div>
            <div class="num">{{data.backPrice || 0}}
            </div>
          </div>
          
        </div>
      </div>
      <div class="date-container" @click="closeDatePicker" v-if="isShowDatePicker">
        <DatetimePicker type="date" @confirm="onDateConfirm" :value="currentDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import { DatetimePicker } from 'vant';
import DateRangePicker from '@/components/select/dateRangePicker.vue'
import {fetchBack} from "@/api/back";
import dayjs from "dayjs";

export default {
  components: {
    Header,
    DatetimePicker,
    DateRangePicker,
  },
 
  data() {
    return {
      data: {},
      currentDate: new Date(),
      year: null,
      month: null,
      day:null,
      beginDate: new Date(`${dayjs(new Date()).format('YYYY')}-01-01`),
      endDate: new Date(dayjs(new Date()).format('YYYY-MM-DD')),
      isShowDatePicker: false
    }
  },
  created() {
    this.year = new Date().getFullYear();
    console.log(new Date().getMonth() + 1)
    this.month = ('00' + (new Date().getMonth() + 1)).slice(-2);
    this.day = ('00' + new Date().getDate()).slice(-2);
    // this.getBack();
    
  },
  methods: {
    async getBack() {
      const res = await fetchBack({
        "endDate": dayjs(this.endDate).format('YYYYMMDD'),
        "beginDate": dayjs(this.beginDate).format('YYYYMMDD'),
      });
      this.data = res.data;
    },

    closeDatePicker() {
      this.isShowDatePicker = false;
    },
    openDatePciker() {
      this.isShowDatePicker = true;
    },
    onDateConfirm(date) {
      this.year = date.getFullYear();
      this.month = ('00' + (date.getMonth() + 1)).slice(-2);
      this.day = ('00' + date.getDate()).slice(-2);
      this.getBack();
    },
    

    onAddBackDetail() {
      this.$router.push({
        path: '/my/back/add',
      });
    },
    onReduceBackDetail() {
      this.$router.push({
        path: '/my/back/reduce',
      });
    },

    handleChangeStart(e) {
      this.beginDate = e.date
      this.getBack();
    },
    handleChangeEnd(e) {
      this.endDate = e.date
      this.getBack();
    }
    
  },
  
  
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  .balance {
    .content {
      width: 326px;
      background: #FFFFFF;
      border: 1px solid #EAEAEA;
      border-radius: 10px;
      margin: 10px auto 0;
      .header {
        height: 44px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EAEAEA;
        box-sizing: border-box;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #2C2C2C;
        .label {
          line-height: 17px;
          color: #CECECE;
          flex: 1;
          text-align: left;
        }
        .date-label {
          margin: 0 4px;
          color: #CECECE;
        }
        .date-icon {
          width: 14px;
          height: 12px;
          margin-bottom: 3px;
        }
      }
    }
    .body {
      padding: 11px 12px 23px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .item {
        margin-top: 15px;
        width: 100%;
        display: flex;
        .label {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #818181;justify-content: flex-start;
          display: flex;
          align-items: center;
        }
        .num {
          margin-left: 8px;
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
          color: #2C2C2C;
          flex:1;
          justify-content: flex-end;
          display: flex;
          align-items: center;

          .right-arrow-icon {
            padding-left: 5px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .button {
      width: 260px;
      &-container {
        width: 100%;
        display: flex;
        margin-top: 8px;
        padding-bottom: 4px;
        justify-content: center;
      }
    }
    .date-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>

