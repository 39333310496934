import { post } from '@/utils/request'

export const fetchBack = async data => {
  return await post('/appdealer/api/report/dealerBack', data)
}

export const fetchAddBackList = async data => {
  return await post('/appdealer/api/report/dealerAddBack', data)
}

export const fetchReduceBackList = async data => {
  return await post('/appdealer/api/report/dealerReduceBack', data)
}

